import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 style={{
      fontSize: '2rem'
    }}>Hi.</h3>
    <p>{`I'm Mahsa Mesbah(MMDev) - currently I am a Frontend developer at `}<a href="https://digikala.com" target="_blank">{`Digikala`}</a>{` team. I love javascript/typescript, and I also like teaching to other people. That's why I helped writing two books to make learning javascript and reactjs easier,
it is open source and available in `}<a href="http://mariotek.ir" target="_blank">{`Mariotek`}</a>{` repository `}{`[ `}<a href="https://github.com/Mariotek/BetterUnderstandingOfJavascript" target="_blank">{`github`}</a>{` ]`}{`. We also translated a book about reactjs interview questions which could help people to have easier interviews `}{`[ `}<a href="https://github.com/Mariotek/reactjs-persian-interview-questions" target="blacnk">{`github`}</a>{` ]`}{`.`}</p>
    <br />
    <a href="/about">more about me</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      