import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br />
    <h4>My social networks:</h4>
    <a style={{
      fontSize: 14
    }} href="https://github.com/mesbah68" target="_blank">Github</a> - <a style={{
      fontSize: 14
    }} href="https://www.linkedin.com/in/mahsa-mesbah/" target="_blank">LinkedIn</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      